<template>
  <el-form-item :error="errorMessage" class="w-full" :class="{'is-invisible': invisible}">
    <el-input
      :class="{ invoiceStyle: invoiceStyle }"
      v-if="type != 'date'"
      :placeholder="!invoiceStyle ? label : ''"
      v-model="inputValue"
      :type="type"
      v-bind="$attrs"
      :size="size"
      :readonly="invoiceStyle"
      :autocomplete="autocomplete ? '' : 'new-password'"
      @input="handleInput()"
    />
    <el-date-picker
      v-else
      v-model="inputValue"
      :class="{ invoiceStyle: invoiceStyle }"
      format="DD/MM/YYYY"
      style="width: 100%"
      :placeholder="label"
      :size="size"
      v-bind="$attrs"
      :readonly="invoiceStyle"
    />
  </el-form-item>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "InputWithValidation",
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
    size: {
      type: String,
      default: "",
    },
    isNoSpace: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Date, Number],
      required: false,
      default: "",
    },
    invoiceStyle: {
      type: Boolean,
      default: false,
    },
    invisible: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  methods: {
    handleInput() {
      if (this.isNoSpace) {
        this.inputValue = this.inputValue.toString().replace(/\s/g, "");
      }
    },
  },
  watch: {
    modelValue: function (newVal) {
      if (this.isNoSpace) {
        newVal = newVal.toString().replace(/\s/g, "");
      }
      this.inputValue = newVal;
    },
  },
};
</script>

<style scoped>
::v-deep(.invoiceStyle .el-input__inner) {
  line-height: 0;
  border: none;
  padding: 0;
  font-size: 0.9rem;
  padding: 0 16px;
}
::v-deep(.invoiceStyle .el-icon-date) {
  display: none;
}
::v-deep(.invoiceStyle) {
  margin-bottom: 0;
}
::v-deep(.el-input__inner) {
  transition: none;
  font-size: 0.9rem;
}
.el-form-item.is-invisible {
  display: inherit !important;
  height: 0;
  margin-bottom: 0;
}
.is-invisible ::v-deep(.el-input) {
  display: none;
}
</style>
